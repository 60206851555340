import * as React from "react";
import * as Sentry from "@sentry/react"
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import CardMedia from '@mui/material/CardMedia';
import { Link } from "react-router-dom";

import ProductsBar from "./ProductsBar";
import GlobalFooter from "../shared/GlobalFooter";

import Logo from "../../assets/images/logo512.png";
import { NewsletterPopup } from "../user/overview/NewsletterPopup";

// placeholder for new pages
const pages = ["Subscribe"];

export function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            src={Logo}
            sx={{ display: { xs: "none", md: "flex" }, height: "2rem" }}
            alt="logo"
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://www.advancedpension.com/home"
            sx={{
              mx: 1,
              display: { xs: "none", md: "flex" },
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Advanced Pension
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={`/${page}`}
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="img"
            src={Logo}
            sx={{ display: { xs: "flex", md: "none" }, height: "2rem" }}
            alt="logo"
          />
          <Typography
            variant="subtitle2"
            noWrap
            component="a"
            href="https://www.advancedpension.com/home"
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Advanced Pension
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block" }}
                href={`/${page}`}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: "flex" }}>
            <Button
              href="/login"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, padding: { xs: "0.25rem", md: "0.5rem 1rem" }, display: "block" }}
            >
              Login
            </Button>
            <Button
              href="https://www.advancedpension.com/create-account"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, padding: { xs: "0.25rem", md: "0.5rem 1rem" }, display: "block" }}
              variant="contained"
            >
                <Typography sx={{ display: { xs: "none", sm: "block", textTransform: "none" }}}>Start your free trial</Typography>
                <Typography sx={{ display: { xs: "block", sm: "none"},  textTransform: "none" }}>Free trial</Typography>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const HomeLandingPage = () => {
  return (
    <div>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <ResponsiveAppBar />
        <NewsletterPopup />
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <Box sx={{ my: "auto", mx: 5, flex: "1 1 60%" }}>
          <Typography
            variant="h2"
            sx={{ fontWeight: 700, my: 2 }}
          >
            Your One-Stop Pension Planner
          </Typography>
          <Typography
            variant="h4"
            sx={{ color: "grey", my: 2 }}
          >
            Simplify your retirement and make the most of your service years
          </Typography>
          <Button
              href="/register"
              sx={{ my: 2, display: "inline-block", fontSize: "1.5rem" }}
              variant="contained"
            >
              Get Started
          </Button>
        </Box>
        <Box sx={{ flex: "1 1 40%", m: 5, maxHeight: { xs: 500, lg: 700 }, overflow: "hidden" }}>
        <CardMedia
          component="video"
          src={"https://pensionapp-assets.s3.us-west-2.amazonaws.com/demo.mov"}
          autoPlay
          muted
        />
        </Box>
        </Box>
        <ProductsBar />
        <GlobalFooter />
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default HomeLandingPage;
